import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import TermsAndConditionsDetails from "./termsAndConditionDetails";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function TermsAndConditions(){
    return (
        <PageContainer>
            <NavBar /> 
            <TermsAndConditionsDetails/>
            <Footer />
        </PageContainer>
        
        
    );
}