import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import { HomePage } from './app/components/HomePage';
import {ToolSteel} from './app/components/ToolSteel';
import { Inventory } from './app/components/Inventory';
import { TermsAndConditions } from './app/components/TermsAndConditions';
import { AlloySteel } from './app/components/AlloySteel';
import {GunSteel} from './app/components/GunSteel';
import { PowderedMetal } from './app/components/PowderedMetal';
import { StainlessSteel } from './app/components/StainlessSteel';
import {Cutting} from './app/components/Cutting';

const AppContainer = styled.div`
  ${tw`
      w-full
      h-full

  `};
`;

function App() {
  return( 
    <AppContainer>
      <Routes>
      <Route path='/' element={<HomePage />}/>
      <Route path="home/*" element={<HomePage />}/>
      <Route path="toolSteel/*" element={<ToolSteel />}/>
      <Route path="termsAndConditions/*" element={<TermsAndConditions />}/>
      <Route path="alloySteel/*" element={<AlloySteel />}/>
      <Route path="firearmSteel/*" element={<GunSteel />}/>
      <Route path="powderedMetal/*" element={<PowderedMetal />}/>
      <Route path="stainlessSteel/*" element={<StainlessSteel />}/>
      <Route path="cutting/*" element={<Cutting />}/>
      </Routes>
    </AppContainer>
  );  
}

export default App;
