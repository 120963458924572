/* This example requires Tailwind CSS v2.0+ */

import SteelAndServicesPic  from "../../../assets/images/SteelsAndServicesPic.jpeg";


export default function SteelAndServices() {
    return (
      <div className="relative bg-blue-800 mt-10" id="steelAndServices">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={SteelAndServicesPic}
            alt=""
          />
          <div className="absolute inset-0 bg-blue-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Steels And Services</h1>
          <p className="mt-6 text-xl text-blue-100 max-w-3xl">
          Our warehouses are stocked and ready to deliver precisely the specialty steel you need! Whether tool steel, stainless steel, alloy steel, powdered metal steel, ductile or gray iron.
          We earned our stripes by not just supplying specialty steel but also through our comprehensive list of services. Whether it be annealing, cold drawing, stress relieving, heat treating 
          or chamfering our professional staff can help you with whatever your need is. We also offer turning, polishing, grinding, and saw cutting.
          </p>
        </div>
      </div>
    )
  }