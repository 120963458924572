/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import CloseUp from "../../../assets/images/closeUp.jpeg";

const tabs = [
  {
    name: 'Steel Cutting',
    features: [
      {
        name: 'Custom Saw Cutting Services',
        description:
        'Our experienced professionals are able to provide you with steel cutting services that will exceed your expectations. Our use of precision cutting equipment and internal processes/quality control allows us to take on any large scale or precision steel cuts delivering the industry best services ensuring customer satisfaction ',
        imageSrc: 'https://bucketeer-95ba2883-9043-428a-8c6d-2517403238c6.s3.amazonaws.com/public/blueMachine.jpeg',
        imageAlt: '',
      },
    ],
  },
  {
    name: 'Chamfering',
    features: [
      {
        name: 'Enhance And Improve Your Steel With A Chamfer',
        description:
        'Bar One Specialty Steel’s innovative machines allow us to make chamfered bars. Whether you are trying to improve the functionality or add more style to your round bars we are here to provide that service to meet your specific needs with custom angles and sizes of that chamfer.',
        imageSrc: 'https://bucketeer-95ba2883-9043-428a-8c6d-2517403238c6.s3.amazonaws.com/public/closeUp.jpeg',
        imageAlt:
          '',
      },
    ],
  },
  {
    name: 'Grinding',
    features: [
      {
        name: 'OD, ID, and Centerless Grinding Services',
        description:
        'At Bar One Specialty Steel we offer precise grinding services. With our experienced professionals and our advanced grinding procedures we can offer precise steel bars with high tolerances and finishes.',
        imageSrc: 'https://bucketeer-95ba2883-9043-428a-8c6d-2517403238c6.s3.amazonaws.com/public/yellowMachine.jpeg',
        imageAlt: 'Walnut organizer base with white polycarbonate trays in the kitchen with various kitchen utensils.',
      },
    ],
  },
]

export default function CuttingFeatures() {
  return (
    <div className="bg-white">
      <section aria-labelledby="features-heading" className="mx-auto max-w-7xl py-32 sm:px-2 lg:px-8">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
          <div className="max-w-3xl">
            <h2 id="features-heading" className="text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">
              Our Services
            </h2>
            <p className="mt-4 text-gray-500">
             In house using top of the line equipment we offer in house custom cuts, grinds and chamfering to fit your specific needs
            </p>
          </div>

          <TabGroup className="mt-4">
            <div className="-mx-4 flex overflow-x-auto sm:mx-0">
              <div className="flex-auto border-b border-gray-200 px-4 sm:px-0">
                <TabList className="-mb-px flex space-x-10">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.name}
                      className="whitespace-nowrap border-b-2 border-transparent py-6 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 data-[selected]:border-blue-600 data-[selected]:text-blue-600"
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </TabList>
              </div>
            </div>

            <TabPanels as={Fragment}>
              {tabs.map((tab) => (
                <TabPanel key={tab.name} className="space-y-16 pt-10 lg:pt-16">
                  {tab.features.map((feature) => (
                    <div key={feature.name} className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8">
                      <div className="mt-6 lg:col-span-5 lg:mt-0">
                        <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
                        <p className="mt-2 text-sm text-gray-500">{feature.description}</p>
                      </div>
                      <div className="lg:col-span-7">
                        <div className="aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5">
                          <img alt={feature.imageAlt} src={feature.imageSrc} className="object-cover object-center" />
                        </div>
                      </div>
                    </div>
                  ))}
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>
        </div>
      </section>
    </div>
  )
}
