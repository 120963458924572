/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/24/outline'
import { HashLink } from "react-router-hash-link";

const features = [
    { name: '4140'},
    { name: '4150'},
    { name: '4320'},
    { name: '4340'},
    { name: '6150'},
    { name: '8620'},
    { name: '9310'},
    { name: 'CrMov'},
    { name: '4140 GBQ'},
    { name: 'MIL-B 11595 Spec Compliant'},

]

export default function AlloySteelPageDetails() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Everything you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Bar One Specialty Steel is one of the most trusted alloy metal distributors in the United States and we’re here to service you. </p>
          <p className="mt-4 text-lg text-gray-500">
          It doesn’t matter if you’re using the material for the marine, military, commercial, industrial, residential, or manufacturing industries, 
          you’ll have the peace of mind that doing business with Bar One Specialty Steel brings.Customer satisfaction is always our top priority. Deliveries are on time, customer service is snappy, 
          and we go the extra mile to ensure everything works out just the way you expect it to.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                
              </div>
            ))}
          </dl>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md ">
                <HashLink
                  to="/home#contactUs"
                  className="mt-12 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-yellow-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Inquire Now!
                </HashLink>
              </div>
              </div>
        </div>
        
      </div>
    </div>
  )
}
