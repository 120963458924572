import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class PowderedVideo extends Component {
    render () {
        return (
        <div className='player-wrapper'>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= 'https://bucketeer-95ba2883-9043-428a-8c6d-2517403238c6.s3.amazonaws.com/public/videos/powderedMetal.mov'
            width='100%'
            height='100%'
            playing={true} 
            loop={true} 
            controls = {true}
            volume={1}
            muted={true}
            playsinline={true}

            />
        </div>
        )
    }
}

export default PowderedVideo;