import React, { Component } from 'react'
import ReactPlayer from 'react-player'


class HomePageVideo extends Component {
    render () {
        return (
        <div className='wrapper mt-14 mb-2 pt-8'>
            <ReactPlayer
            className='player object-fill'
            url= 'https://bucketeer-95ba2883-9043-428a-8c6d-2517403238c6.s3.amazonaws.com/public/centernewlogo.mp4'
            width='100%'
            height="100%"
            playing={true} 
            loop={true} 
            controls = {true}
            volume={1}
            muted={true}

            />
        </div>
        )
    }
}

export default HomePageVideo;