/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { CheckIcon } from '@heroicons/react/16/solid'
import { HashLink } from "react-router-hash-link";

const features = [
    { name: 'A2'},
    { name: 'A6'},
    { name: 'D2'},
    { name: 'D3'},
    { name: 'S1'},
    { name: 'S5'},
    { name: 'S7'},
    { name: 'H13'},
    { name: 'O1'},
    { name: 'L6'},
    { name: 'W1'},

]

export default function ToolSteelPageDetails() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Everything you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Bar One Specialty Steel is one of the top leading distributors of tool steel in the United States</p>
          <p className="mt-4 text-lg text-gray-500">
          You can trust the quality of our steel and commitment to our customers. It doesn’t matter where you are in the United States, we’ll be able to get your product to you on time because quality and customer satisfaction are our highest priority.
          From your backyard handyman to your busy contractor, to your top-of-the-line distributor, tools and the quality steel they’re made of literally help shape our lives. So it goes without saying that you should always stick with the highest quality distributor with the best reputation.
          Tool Steel is defined by the following four criteria: distinctive hardness, resistance to abrasion, resistance at elevated temperatures to deformation, and their ability to hold a cutting edge.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                
              </div>
            ))}
          </dl>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md ">
                <HashLink
                  to="/home#contactUs"
                  className="mt-12 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-yellow-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Inquire Now!
                </HashLink>
              </div>
              </div>
        </div>
        
      </div>
    </div>
  )
}
