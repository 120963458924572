import FrontPagePic from "../../../assets/images/bar_one_front.jpg";

export default function CuttingHeader() {
    return (
      <div className="bg-white">
        <div className="relative bg-gray-900">
          {/* Decorative image and overlay */}
          <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
            <img
              alt=""
              src={FrontPagePic}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50" />
  
          <div className="relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0">
            <h1 className="text-4xl font-bold tracking-tight text-white lg:text-6xl">Cutting, Grinding and Chamfering Services</h1>
            <p className="mt-4 text-xl text-white">
              At Bar One Specialty Steel we pride ourselves on getting you exactly what you need. Whether you need cutting, grinding or chamfering services we are able to do it all 
            </p>
            <a
              href="#"
              className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100"
            >
              Inquiry Now!
            </a>
          </div>
        </div>
      </div>
    )
  }
  