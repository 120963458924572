
import { HashLink } from "react-router-hash-link";
import Crane from "../../../assets/images/crane.jpeg";
import Bar from "../../../assets/images/bar_cutting.jpeg";
import Machine from "../../../assets/images/cut_machine.jpeg";
import SideMachine from "../../../assets/images/side_machine.jpeg";
import FrontMachine from "../../../assets/images/front_machine.jpeg";
import Inventory from "../../../assets/images/inventory_cut.jpeg";
import CutSteel from "../../../assets/images/cut_steel.jpeg";
import YellowMachine from "../../../assets/images/yellowMachine.jpeg";
import CloseUp from "../../../assets/images/closeUp.jpeg";
import Panel from "../../../assets/images/panel.jpeg";


export default function CuttingSection() {
    return (
        <div className="relative overflow-hidden bg-white">
          <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
            <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
              <div className="sm:max-w-lg">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  In house cutting services
                </h1>
                <p className="mt-4 text-xl text-gray-500">
                  Using top of the line equipment we offer in house custom cuts, grinds and chamfering
                </p>
              </div>
              <div>
                <div className="mt-10">
                  {/* Decorative image grid */}
                  <div
                    aria-hidden="true"
                    className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                  >
                    <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                      <div className="flex items-center space-x-6 lg:space-x-8">
                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                          <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                            <img
                              alt=""
                              src={YellowMachine}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              alt=""
                              src={FrontMachine}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              alt=""
                              src={Bar}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              alt=""
                              src={CloseUp}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              alt=""
                              src={Machine}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                        <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              alt=""
                              src={CutSteel}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                          <div className="h-64 w-44 overflow-hidden rounded-lg">
                            <img
                              alt=""
                              src={Panel}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    
                  <a
                    href="#"
                    className="inline-block rounded-md border border-transparent bg-blue-600 px-8 py-3 text-center font-medium text-white hover:bg-blue-700"
                  >
                    <HashLink to="/cutting#top" type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Learn More</span>
                    </HashLink>
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
  