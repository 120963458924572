/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/24/outline'
import { HashLink } from "react-router-hash-link";

const features = [
    { name: '9V'},
    { name: '10V'},
    { name: 'S90V'},
    { name: 'M2'},
    { name: 'M4'},
    { name: 'M10'},
    { name: 'M42'},
    { name: 'A11'},

]

export default function PowderedMetalPageDetails() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Everything you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Bar One Specialty Steel pay such close attention to detail; you would think our workers are direct descendants of those who first conceptualized the process.</p>
          <p className="mt-4 text-lg text-gray-500">
          We’re experts in powdered metal and we have the reputation to back up the claim. We ensure that every powdered metal delivery is as good as the last because we thrive off of complete customer satisfaction and high quality. You’ll be completely satisfied when our high quality powdered metal arrives on time and in immaculate condition. That paired with our stellar customer service, you’ll enjoy the peace of mind that is a byproduct of doing business with Bar One Specialty Steel.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                
              </div>
            ))}
          </dl>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md ">
                <HashLink
                  to="/home#contactUs"
                  className="mt-12 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-yellow-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Inquire Now!
                </HashLink>
              </div>
              </div>
        </div>
        
      </div>
    </div>
  )
}
