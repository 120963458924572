/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import Logo  from "../../../assets/images/logo.jpeg";
import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const solutions = [
  {
    name: 'Tool Steel',
    to: '/toolSteel#top',
  },
  {
    name: 'Stainless Steel',
    to: '/stainlessSteel#top',
  },
  { name: 'Firearm Steel', to: '/firearmSteel', icon: CheckIcon },
  {
    name: 'Alloy Steel',
    to: '/alloySteel#top',
  },
  {
    name: 'Powdered Metal',
    to: '/powderedMetal#top',
  },
]


export default function Example() {
  return (
    <div className="bg-white">
      <header className="fixed top-0 z-50 w-full">
        <Popover className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <HashLink to='/home#top'>
                <span className="sr-only">Workflow</span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src={Logo}
                  alt=""
                />
              </HashLink>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                      )}
                    >
                      <span>Steels</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-500'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2 items-center justify-center">
                            {solutions.map((item) => (
                              <HashLink
                                key={item.name}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50" 
                                to={item.to}                              >
                                <div className="ml-4 mt-3">
                                  <p className="text-base font-medium text-gray-900 items-center justify-center ">{item.name}</p>
                                </div>
                              </HashLink>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                  
                )}
              </Popover>

              <HashLink to="/home#steelAndServices" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Services
              </HashLink>
               <HashLink to="/cutting#top" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Cutting and Grinding
              </HashLink> 
              <HashLink to="/home#contactUs" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Contact Us
              </HashLink>
            </Popover.Group>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={Logo}
                        alt="Workflow"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-1 gap-7">
                      {solutions.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}      
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                        >
                          <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    <HashLink to="/home#steelAndServices" className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Services
                    </HashLink>
                     <Link to="/cutting" className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Cutting and Grinding
                    </Link>
                    <HashLink to="/home#contactUs" className="text-base font-medium text-gray-900 hover:text-gray-700">
                      Contact Us
                    </HashLink>
                  </div>
                  
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>
    </div>  
  )
}
