/* This example requires Tailwind CSS v2.0+ */
export default function PowderedMetalPageHeader() {
    return (
      <div className="bg-white mt-12">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 mt-12">
          <div className="text-center pt-12 mt-14">
            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">Having been around since 1200 BC, powdered metallurgy is definitely nothing new, but that doesn’t mean it doesn’t continue to spur innovation</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Powdered Metal
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            From the space age to ferrites for microwaves to the filament in a light bulb, powdered metals have helped mankind progress since its inception.  Here at Bar One Specialty Steel we make sure to pay homage to powdered metal’s long history by ensuring we distribute only the highest quality products.
            </p>
          </div>
        </div>
      </div>
    )
  }
  