import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Inventory from "../../../assets/images/inventory_cut.jpeg";



export default function GrindingInfo() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          aria-hidden="true"
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
        >
          <defs>
            <pattern
              x="50%"
              y={-1}
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-blue-600 sm:text-4xl">All About Steel Grinding</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              Grinding round bars is a process used to improve the surface finish, achieve precise dimensions, or shape the bars for specific applications.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt=""
            src={Inventory}
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
              Why would you need grinding? The main reasons are to smooth out rough surfaces and achieve a fine finish, achieve precise diameters and tolerances, shape the round bar for specific applications or to create custom profiles, and to eliminate surface imperfections, such as rust, corrosion, or tool marks. Below are the differnt type of grinding machines available.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Centerless Grind</strong>Used for high-precision grinding of round bars without the need for centers or fixtures. The bar is fed through the grinder between a grinding wheel and a regulating wheel, allowing for continuous and accurate grinding.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Cylindrical Grinder</strong> This machine supports the round bar between centers or in a chuck and rotates it while the grinding wheel moves along the length of the bar to achieve the desired finish and dimensions.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Surface Grinder</strong> Used primarily for flat surfaces but can be adapted for certain cylindrical grinding tasks. The round bar is held in a vise or fixture, and the grinding wheel moves across the surface.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-blue-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Bench Grinder</strong> A manual tool that can be used for smaller, less precise grinding tasks or for deburring edges. It’s not typically used for high-precision work.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                There are various grinding techniques that can be used such as centerless grinding, cylindrical grinding and surface grinding. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
