import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import PowderedMetalPageHeader from "./PowderedMetalPageHeader";
import PowderedVideo from "./powderedMetalMov";
import PowderedMetalPageDetails from "./powderedMetalPageDetails";
import GunSteelOfferings from "../GunSteel/gunSteelOfferings";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function PowderedMetal(){
    return (
        <PageContainer>
            <NavBar /> 
            <PowderedMetalPageHeader/>
            
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <PowderedVideo/>
                </div>
            </div>
            <PowderedMetalPageDetails/>
            <GunSteelOfferings />
            <Footer />
        </PageContainer>
        
        
    );
}