/* This example requires Tailwind CSS v2.0+ */
export default function StainlessSteelPageHeader() {
    return (
      <div className="bg-white mt-12">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 mt-12">
          <div className="text-center pt-12 mt-14">
            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">We distribute stainless steel in a variety of shapes. </h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Stainless Steels
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Stainless Steel is the name given to a class of steel where more than 10% of chromium is added to ordinary steel and the surface oxide is transformed – very thin (virtually invisible), 
            it is protective to a wide range of corrosive media. There are over 150 different grades within a variety of types. Milled into coils, sheets, plates, bars, wire or tubing.
            </p>
          </div>
        </div>
      </div>
    )
  }
  