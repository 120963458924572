import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import TopSection from "./topSection";
import CompanyFeatures from "./companyFeatures";
import SteelAndServices from "./steelAndServices";
import SteelsSection from "./steelsSection";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import ContactUs from "../ContactUs/contactUs";
import HomePageVideo from "./homePageVideo";
import CuttingSection from "./cuttingSection";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function HomePage(){
    return (
        <PageContainer>
            <NavBar /> 
            <HomePageVideo />
            <TopSection />
            <CuttingSection />
            <CompanyFeatures />
            <SteelAndServices />
            <SteelsSection />
            <ContactUs />
            <Footer />
        </PageContainer>
        
        
    );
}