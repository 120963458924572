/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/24/outline'
import { HashLink } from "react-router-hash-link";

const features = [
    { name: '303'},
    { name: '304'},
    { name: '304L'},
    { name: '316'},
    { name: '410'},
    { name: '416'},
    { name: '416R'},
    { name: '420'},
    { name: '440C'},
    { name: '17-4PH'},
]

export default function StainlessSteelPageDetails() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Everything you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Other stainless steel distribution companies may cause you a headache due to late arrivals and bad customer service, but not us. 
          This is Bar One Specialty Steel and there’s a reason we’re one of the leading distributors of stainless steel.</p>
          <p className="mt-4 text-lg text-gray-500">
          Whether you’re based in the Midwest like us or anywhere in the United States of America, we’ll get you the highest quality stainless steel on schedule because customer satisfaction is our top priority. We distribute 
          stainless steel in a variety of shapes. Whether you’re looking for bars, rounds, wire, plates, hexagons, rectangles, squares or a shape we’ve never even heard of, we’ll be able to cut it for you. Bar One Specialty 
          Steel understands that you expect quality, punctuality, and for things to work out when they’re supposed to. That’s why we go above and beyond every day for our customers to make sure it all goes seamless..
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                
              </div>
            ))}
          </dl>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md ">
                <HashLink
                  to="/home#contactUs"
                  className="mt-12 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-yellow-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Inquire Now!
                </HashLink>
              </div>
              </div>
        </div>
        
      </div>
    </div>
  )
}
