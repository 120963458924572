/* This example requires Tailwind CSS v2.0+ */
export default function AlloySteelPageHeader() {
    return (
      <div className="bg-white mt-12">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 mt-12">
          <div className="text-center pt-12 mt-14">
            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">Being much, much harder than pure iron, the mixture of iron elements and carbon that make up alloy steel</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Alloy Steels
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Alloy steels are perfect for a myriad of uses, many of which we couldn’t do without in today’s society.No matter what you may be using alloy steel for, 
            we guarantee our alloy metals are manufactured to the highest possible quality.
            </p>
          </div>
        </div>
      </div>
    )
  }