import { ChevronRightIcon} from '@heroicons/react/20/solid'
import SideMachine from "../../../assets/images/side_machine.jpeg";



export default function ChamferInfo() {
  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              alt=""
              src={SideMachine}
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
            />
          </div>
        </div>
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            <p className="text-base font-semibold leading-7 text-indigo-600"></p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What is a chamfer?</h1>
            <p className="mt-6 text-xl leading-8 text-gray-700">
            Chamfering a round bar involves creating a beveled edge or removing a small portion of the bar's edge to improve its appearance, ease of handling, or fit in assemblies
            </p>
            <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
              <p>
                The purpose of chamfering is to allow for improved safety, better fit, aesthetic appeal and ease of machining. Below are a few chamfering techniques
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Manual Chamfering</strong>Use a file, deburring tool, or bench grinder for manual chamfering. This is best for small quantities or for fine-tuning edges.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Machine Chamfering</strong> For more consistent results or larger quantities, machine chamfering is preferred. Adjust the machine settings to achieve the desired angle and depth.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ChevronRightIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                  <span>
                    <strong className="font-semibold text-gray-900">Angle and Depth</strong> The angle of the chamfer (e.g., 45 degrees) and depth should be carefully set based on your requirements. Most chamfering tools and machines allow you to adjust these parameters.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
              Chamfering is a fundamental process in machining that can significantly impact the functionality and appearance of parts.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
