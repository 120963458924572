/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import TermsPic from "../../../assets/images/termsAndConditionsPic.jpeg";

export default function TermsAndConditionsDetails() {
  return (
    <div className="bg-white overflow-hidden mt-12">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Bar One Specialty Steel</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Terms And Conditions
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={TermsPic}
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
            
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500">
              All sales by Bar One Specialty Steel Inc, and its affiliates and subsidiaries (collectively “Bar One”) are made subject to the following Terms and Conditions. Bar One expressly rejects any different or additional terms or conditions contained in any documents submitted by Buyer. Bar One’s acceptance of any purchase order and/or sale of any goods is expressly made conditional on Buyer’s acceptance of these terms and conditions, which is presumed by Buyer’s express acceptance thereof or Buyer’s acceptance of delivery of the goods sold.
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
              1. Except as otherwise agreed in a writing signed by Buyer and Bar One, these Terms and Conditions constitute the entire agreement between Bar One and Buyer relating to the sale of such goods by Bar One. Terms or conditions contained in any document issued by Buyer that in any manner purport to alter, modify, change, suspend, or add to any term or condition contained herein shall be deemed excluded from such Buyer document and waived by Buyer. Bar One and Buyer expressly agree that Bar One may modify these terms and conditions from time to time, and such modifications shall be binding upon Buyer.
              </p>
              <p>
              2. All Buyer orders are based on prices in effect at the time of shipment. Bar One reserves the right to change prices without notice. Buyer cannot modify, cancel, or otherwise alter orders without Bar One’s written consent.
              </p>
              <p>
              3. Unless otherwise indicated, all deliveries are FOB loaded Bar One’s shipping facility. Unless otherwise indicated, shipments are based on piece counts and theoretical weight. Risk of loss or damage in transit shall be borne by Buyer, and claims shall be made directly with carrier. Buyer may pick up the goods at Bar One’s shipping facility provided Buyer provides Bar One with twenty-four (24) hours advance notice. Buyer shall indemnify and hold harmless Bar One from and against any claims, damages or liabilities suffered by Bar One resulting from any acts or omissions of carrier.
              </p>
              <p>
              4. Delivery dates are approximate. Bar One shall not be responsible for non-shipment of goods or delays in delivery or performance due to causes beyond its reasonable control, including, but not limited to, acts of God; acts of Buyer, strikes or other labor disturbances; Bar One’s inability to obtain materials, or material increase in the cost of fuel, raw materials or parts; delays in transportation; repairs to equipment; fires; or accidents. Acceptance of goods upon delivery shall constitute a waiver by Buyer of any claim for damages on account of non-shipment, under-shipment or delay.
              </p>
              <p>
              5. Subject to standard manufacturing variations, Bar One warrants that the goods furnished meet applicable specifications. BAR ONE MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, AND SPECIFICALLY EXCLUDES ALL WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPPOSE.
              </p>
              <p>
              6. No claim for damages for goods that do not conform to specifications will be allowed unless Bar One is given immediate notice after delivery of goods to the first destination to which they are shipped and allowed an opportunity to inspect them. Goods for which damages are claimed shall not be returned, repaired, or discarded without Bar One’s written consent. BUYER’S EXCLUSIVE REMEDY AGAINST BAR ONE, AND BAR ONE’S SOLE OBLIGATION, FOR ANY AND ALL CLAIMS, WHETHER FOR BREACH OF CONTRACT, WARRANT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, SHALL BE LIMITED TO BAR ONE’S REPLACING GOODS THAT DO NOT CONFORM TO SPECIFICATIONS OR, AT BAR ONE’S OPTION, REFUNDING THE PURCHASE PRICE. IN NO EVENT SHALL BAR ONE HAVE ANY LIABILITY FOR DAMAGES IN AN AMOUNT EXCEEDING THE PURCHASE PRICE OF THE GOODS SOLD HEREUNDER, NOR SHALL BAR ONE HAVE ANY LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
              </p>
              <p>
              7. If, in Bar One’s opinion, Buyer’s credit becomes impaired, Bar One may suspend performance until such time as Bar One has received full payment for any goods already delivered or in process and is satisfied (in its sole discretion) as to Buyer’s credit for future deliveries. If Bar One suspends performance and later proceeds with such order, Bar One shall be entitled to such extension of time for performance as its necessitated by the suspension.              </p>
              <p>
              8. All taxes of any kind levied by any federal, state, municipal or other governmental authority, which tax Bar One is required to collect or pay with respect to the production, sale or shipment of goods sold to Buyer shall be the responsibility of Buyer. Buyer agrees to pay all such taxes and further agrees to reimburse Bar One for any such payments made by Bar One.              </p>
              <p>
              9. Checks or payment, whether full or partial, received from or for the account of Buyer, regardless of writings, legends, or notations upon such checks or payments, or regardless of other writings, statements, or
documents, shall be applied, by Bar One against any amount owing by Buyer with full reservation of all of Bar One’s rights, without an accord and satisfaction of Buyer’s liability.              </p>
              <p>
              10. Unless otherwise indicated, payment terms ½ 10 net 30 days from date of invoice. In the event Buyer fails to make timely payment to Bar One of any amounts due and owing to Bar One, Bar One shall have the right to terminate any Buyer order or any unfulfilled portion thereof, and Bar One or any affiliate thereof may terminate any other agreement between Bar One or such affiliate and Buyer. Bar One may charge interest on the outstanding balance at an annual rate of 12% or the highest rate allowed by law (whichever is less). Bar One shall have the right to employ an attorney to collect the balance due, and Buyer agrees to pay all collection costs incurred by Bar One, including its reasonable attorney’s fees.              </p>
              <p>
              11. This agreement shall be governed by the laws of the State of Illinois Buyer, acting for itself and its successors and assigns, hereby expressly and irrevocably consents to the exclusive jurisdiction of the state and federal courts of Illinois for any litigation which may arise out of or be related to this agreement. Buyer waives any objection based on forum non conveniens or any objection to venue of any such action.              </p>
              <p>
              12. Bar One reserves the right to enforce these terms and conditions at any time and none shall be deemed waived unless such waiver is in writing signed by a duly authorized employee of Bar One. All right and remedies granted herein are in addition to all remedies available at law or in equity.              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
