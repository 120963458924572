export default function CuttingVideoHeader() {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-blue-600 sm:text-6xl">A look into our facilty and cutting process</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            An insider look into one of our state of the art machines making production cuts in our facility! We pride ourselves in providing precise cuts to meet your needs using precision cutting equipment, using quality control protocols and having experienced machinists.
          </p>
        </div>
      </div>
    )
  }
  