import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class CuttingVideo extends Component {
    render () {
        return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> 
            <div className="mx-auto max-w-3xl">
            <div className='player-wrapper'>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= 'https://bucketeer-95ba2883-9043-428a-8c6d-2517403238c6.s3.amazonaws.com/public/bar_one_cutting.mp4'
            width='100%'
            height='100%'
            playing={true} 
            muted={true}
            loop={true} 
            controls = {true}
            playsinline={true}

            />
        </div>
            </div>
        </div>
        )
    }
}

export default CuttingVideo;