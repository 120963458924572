import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import StainlessSteelPageHeader from "./stainlessSteelPageHeader";
import StainlessVideo from "./stainlessMov";
import StainlessSteelPageDetails from "./stainlessSteelPageDetails";
import GunSteelOfferings from "../GunSteel/gunSteelOfferings";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function StainlessSteel(){
    return (
        <PageContainer>
            <NavBar /> 
            <StainlessSteelPageHeader/>
            
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <StainlessVideo/>
                </div>
            </div>
            <StainlessSteelPageDetails/>
            <GunSteelOfferings/>
            <Footer />
        </PageContainer>
        
        
    );
}