import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import FrontPagePic from "../../../assets/images/bar_one_front.jpg";


export default function Example() {
  return (
    <div className="relative bg-white" id="contactUs">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-blue-600 sm:text-3xl">Contact Us</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>1063 Pierson Drive</p>
                  <p>Batavia, IL 60510</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">
                        Toll Free: (877) 541-6421<br />
                        Phone: (630) 937-0600<br />
                        Fax: (630) 937-0601</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">info@baronespecialty.com</span>
                </dd>
              </div>
            </dl>
            
          </div>
        </div>
        <div className="bg-white w-full py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className='w-full h-full'>
          <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={FrontPagePic}
          alt=""
        />
          </div>
        </div>

      </div>
    </div>
  )
}
