/* This example requires Tailwind CSS v2.0+ */
export default function ToolSteelPageHeader() {
    return (
      <div className="bg-white mt-12">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 mt-12">
          <div className="text-center pt-12 mt-14">
            <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">Without tool steel not much would get accomplished</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Tool Steels
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Bar One Specialty Steel will be able to provide a variety of shapes and forms upon request including bars, rounds, wire, plates, hexagons, rectangles, and squares or you can contact us about a specialty shape you need your tool steel to be delivered in.
            It doesn’t matter where you are in the United States, we’ll be able to get your product to you on time because quality and customer satisfaction are our highest priority.
            </p>
          </div>
        </div>
      </div>
    )
  }
  