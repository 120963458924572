import {Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


/* This example requires Tailwind CSS v2.0+ */
const navigation = {
    main: [
      { name: 'Home', to: '/home#top' },
      { name: 'Steels', to: '/home#steelAndServices' },
      { name: 'Services', to: '/home#steelAndServices' },
      // { name: 'Inventory', to: '/inventory#top' },
      { name: 'Contact Us', to: '/home#contactUs' },
      { name: 'Terms and Conditions', to: '/termsAndConditions#top' },
    ],
    
  }
  
  export default function Example() {
    return (
      <footer className="bg-blue-600 mt-14">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
            {navigation.main.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <HashLink to={item.to} className="text-base text-white hover:text-gray-900">
                  {item.name}
                </HashLink>
              </div>
            ))}
          </nav>
          <div className="mt-8 flex justify-center space-x-6">
            {/* {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))} */}
          </div>
          <p className="mt-8 text-center text-base text-white">&copy; 2021 Bar One Specialty Steel. All rights reserved.</p>
        </div>
      </footer>
    )
  }
  