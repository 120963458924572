import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import CuttingFeatures from "./cuttingFeatures";
import CuttingHeader from "./cuttingHeader";
import CuttingVideo from "./cuttingVideo";
import CuttingVideoHeader from "./cuttingVideoHeader";
import GunSteelOfferings from "../GunSteel/gunSteelOfferings";
import ChamferInfo from "./chamferInfo";
import GrindingInfo from "./grindingInfo";
import ContactUs from "../ContactUs/contactUs";


const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function Cutting(){
    return (
        <PageContainer>
            <NavBar /> 
            <CuttingHeader />
            <CuttingFeatures />
            <CuttingVideoHeader/>
            <CuttingVideo/>
            <GunSteelOfferings/>
            <ChamferInfo />
            <GrindingInfo />
            <ContactUs />
            <Footer />
        </PageContainer>
        
        
    );
}