/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/24/outline'
import { HashLink } from "react-router-hash-link";

const features = [
    { name: 'Stainless Steels', description: '416R, 416, 416HT, 410, 17-4PH'},
    { name: 'Alloy Steels', description: '1137, 4140, 4150, 4150MOD, 41V45, CrMoV, 9310AQ, 8620, 4130, 4320'},
    { name: 'Applications', description: 'Gun Barrels, Slides, Actions Bolts'},
    { name: 'Typical Machining Apps', description: 'Gun Drilling, Button Rifling, Reaming, Polishing, Lapping'},
    { name: 'Made for All Industries', description: 'Military, Law Enforcement, Consumer'},

]

export default function GunSteelPageDetails() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-blue-600 uppercase tracking-wide">Everything you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">Bar One Specialty Steel is the LARGEST stocking distributor of firearm steels in the nation</p>
          <p className="mt-4 text-lg text-gray-500">
          You can trust the quality of our steel and commitment to our customers. All steels can be supplied in various hardness ranges according to your specs. Non-destructive testing incorporated in the production of all gun-quality steels.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md ">
                <HashLink
                  to="/home#contactUs"
                  className="mt-12 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-yellow-400 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                >
                  Inquire Now!
                </HashLink>
              </div>
              </div>
        </div>
        
      </div>
    </div>
  )
}
