import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import ToolSteelPageHeader from "./toolSteelPageHeader";
import ToolVideo from "./toolMov";
import ToolSteelPageDetails from "./toolSteelPageDetails";
import GunSteelOfferings from "../GunSteel/gunSteelOfferings";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function ToolSteel(){
    return (
        <PageContainer>
            <NavBar /> 
            <ToolSteelPageHeader/>
            
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <ToolVideo/>
                </div>
            </div>
            <ToolSteelPageDetails/>
            <GunSteelOfferings/>
            <Footer />
        </PageContainer>
        
        
    );
}