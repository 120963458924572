import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import GunSteelPageHeader from "./gunSteelPageHeader";
import GunVideo from "./gunSteelVideo";
import GunSteelPageDetails from "./gunSteelPageDetails";
import GunSteelOfferings from "./gunSteelOfferings";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function GunSteel(){
    return (
        <PageContainer>
            <NavBar /> 
            <GunSteelPageHeader/>
            
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <GunVideo/>
                </div>
            </div>
            <GunSteelPageDetails/>
            <GunSteelOfferings/>
            <Footer />
        </PageContainer>
        
        
    );
}