import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import NavBar from "../navbar/navBar";
import Footer from "../footer/footer";
import AlloySteelPageHeader from "./alloySteelPageHeader";
import AlloyVideo from "./alloyMov";
import AlloySteelPageDetails from "./alloySteelPageDetails";
import GunSteelOfferings from "../GunSteel/gunSteelOfferings";

const PageContainer = styled.div`
    ${tw`
        w-full
        h-full
    `}
`;

export function AlloySteel(){
    return (
        <PageContainer>
            <NavBar /> 
            <AlloySteelPageHeader/>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <AlloyVideo/>
                </div>
            </div>
            <AlloySteelPageDetails/>
            <GunSteelOfferings/>
            {/* <AirCraftDetails /> */}
            <Footer />
        </PageContainer>
        
        
    );
}