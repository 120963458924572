import ToolSteel from "../../../assets/images/toolsPic.jpeg";
import StainlessSteel from "../../../assets/images/StainlessSteel.jpeg";
import Alloys from "../../../assets/images/ALLOY.jpeg";
import GunSteel from "../../../assets/images/FIREARMSTEEL.jpg";
import InventoryPic from "../../../assets/images/inventoryPic.jpeg";
import PowderedPic from "../../../assets/images/Powdered.jpeg";
import CloseUp from "../../../assets/images/closeUp.jpeg";
import { HashLink } from "react-router-hash-link";

  export default function SteelsSection() {
    return (
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8 mt-8 mr-8 ml-8 mb-8">
            <li className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={ToolSteel} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <HashLink to="/toolSteel#top" type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Tool Steel</span>
                </HashLink>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none text-center">Tool Steel</p>
          </li>
          <li className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={StainlessSteel} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <HashLink to="/stainlessSteel#top" type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Stainless Steel</span>
                </HashLink>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none text-center">Stainless Steel</p>
          </li>
          <li className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={GunSteel} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <HashLink to="/firearmSteel#top" type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Firearm Steel</span>
                </HashLink>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none text-center">Firearm Steel</p>
          </li>
          <li className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={Alloys} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <HashLink to="/alloySteel#top" type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Alloy</span>
                </HashLink>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none text-center">Alloy</p>
          </li>
          <li className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={PowderedPic} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <HashLink to='/powderedMetal#top' type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Powdered Metal</span>
                </HashLink>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none text-center">Powdered Metal</p>
          </li>
         <li className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                <img src={CloseUp} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                <HashLink to='/cutting#top'  type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">Inventory</span>
                </HashLink>
                </div>
                <p className="mt-2 block text-base font-medium text-gray-900 truncate pointer-events-none text-center">Cutting and Grinding Services</p>
          </li>
        </ul>
    )
  }
